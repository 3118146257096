/* 	CUSTOM FONTS 
	- Generated by Font Squirrel (https://www.fontsquirrel.com) on September 28, 2018
	- Font "Pixel Tactical" by Pixel Sagas is clear for reuse at Dafont.com (https://www.dafont.com/pixel-tactical.font) 
*/
@font-face {
  font-family: 'Pixel Tactical';
  src: url('../fonts/pixel_tactical.woff2') format('woff2'),
       url('../fonts/pixel_tactical.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'GameCuben';
  src: url('../fonts/GameCube.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/** GLOBAL STYLES **/
html {
  height: 100%;
  background-color: #373;
}
body{
  margin: 0;
  padding: 0;
  font-size: 100%;
  font-family: "Segoe UI",Frutiger,"Frutiger Linotype","Dejavu Sans","Helvetica Neue",Arial,"sans-serif" !important;
  /*background-color: #9bbc0f;*/
  /*background-color: #373;*/
  background-image: linear-gradient(#9bbc0f, #373);
  position: absolute;
  width: 100%;
  height: 100%;
}

tr {
  border-top: 1px solid #CCC;
}

header {
  margin-top: 0;
  padding-bottom: 8px;
}

h1 {
  margin-bottom: 0;
}

h1 a{
  font-family: "GameCuben";
}

h3, h4{
  font-family: "GameCuben";
  font-size: 90%;
  text-transform: uppercase;
  line-height: 2em;
}

audio {
  margin-top: 10px;
}

form{
  padding: 10px;
  color: #eee;
}

hr{
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

a{
text-decoration: none;
color: #c85483;
margin: 0;
padding: 0;
}

a:hover{
color: #c6bbe6;
}

article{
text-align: justify;
}

p:last-child{
  margin-bottom: 0;
}

nav{
  text-align: center;
  font-weight: bold;
  font-size: 1em;
  position: relative;
  width: 100%;
  margin-bottom: 1.5%;
  z-index: 99;
}

.navbar-nav{
  margin-left: 15px;
}

nav a{
text-decoration: none;
padding: 0.5% 3%;
}

nav a:link{
color: #EEE;
}

nav a:visited{
color: #CCC;
}

nav a:hover{
color: #c6bbe6;
}

nav ul li{
  list-style: none;
  padding: 0.5% 0;
  margin-right: 4px;
  margin-bottom: 3px;
  background-color: #373737;
  display: inline;
  background: radial-gradient(circle, #EEEEEE 70%, #373737 300%);
  border: 2px solid #999;
  border-radius: 5px;
  text-transform: uppercase;
  font-size: 120%;
  box-shadow: 1px 0px 4px #999;
}

nav ul li:hover{
  border-color: #c85483;
}

nav ul .active{
  border-color: #6f4bcf;
}

iframe{
  width: 100%;
  min-height: 259px;
  border: 1px solid #eee;
  border-radius: 5px;
}

/** ID STYLES **/

#root{
  background-image: url("../images/DMPattern.png");
  width: 100%;
  min-height: 100%;
}

#wrapper {
  background-color: #EEE;
  box-shadow: inset 0px 0px 12px #000; 
  border-radius: 20px;
  padding-top: 1%;
  border: 0px solid #3c3c3c;
  width: 1024px;
  margin: 0 auto;
  position: relative;
  top: 12px;
}

#banner-overlay{
  width: 100%;
  position: absolute;
box-shadow: inset 0px 0px 20px #000;
  border: 2px solid #3c3c3c;
/*background-color: #9bbc0f;*/
background-image: url("../images/DMPattern.png");
background-repeat: repeat;
  border: 3px solid #373737;
}

#site-title{
  margin: 20px, 0;
  text-shadow: 2px 1px 2px #555;
}

#site-title a{
  color: #6f4bcf;
  text-transform: uppercase;
  font-size: 3rem;
  padding-top: 0;
  text-align: center;
  display: block;
}

#slogan{
  font-family: GameCuben;
  text-transform: uppercase;
  color: #e3e3e3;
  text-shadow: 1px 1px 2px #373737;
  text-align: center;
  line-height: 1.5rem;
}

#date-accessed{
  display: block;
  text-align: right;
}

#featured-project-button{
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
}

#featured-project-image{
  width: 100%;
  height: 100%;
}

/** CLASS STYLES **/
.gb-green-box{
  width: 100%;
  height: 100%;
  background-color: #75a31c;
  display: block;
  padding: 10px;
  color: #eee;
}

.gb-blue-box{
  width: 100%;
  height: 100%;
  background-color: #428bca;
  display: block;
  padding: 10px;
  color: #eee;
}

.gb-purple-box{
  width: 100%;
  height: 100%;
  background-color: #6f4bcf;
  display: block;
  padding: 10px;
  color: #eee;
}

.gb-purple-box-lg{
  width: 100%;
  height: 100%;
  background-color: #6f4bcf;
  display: block;
  padding: 10px;
  color: #eee;
}

.gb-pink-box{
  width: 100%;
  height: 100%;
  background-color: #c85483;
  display: block;
  padding: 10px;
  color: #eee;
}

.footer {
  line-height: 30px;  
  padding: 10px;
  background-color: #373737;
  box-shadow: inset 0px 0px 20px #000;
  border-radius: 8px 8px 20px 20px;
  margin-bottom: 8px;
}

.footer .row {
  text-align: center;
}

.container-product {
  background-color: #FAF9F7;
  padding: 10px;
}

.modal-dialog{
  max-width: 90%;
}

.carousel {
  margin: 0 auto;
  width: 97%;  
  box-shadow: 0px 0px 6px #000;
  border: 3px solid #373737;
  border-radius: 8px;
}

header .row:nth-child(2){
  margin-bottom: 2%;
}

header .row:nth-child(3){
  margin: 0;
}
.carousel-caption p {
  background-color: #373737;
  opacity: 0.75;
}

.game-screen-wrapper{
  background-color: #373737;
  padding:20px 0 0;
  margin:0 12px;
  border-radius: 12px 48px 12px 48px;
}

.game-screen-header{
position: relative;
width: 90%;
margin: 0 auto;
box-shadow: inset 0 0 20px #000;
background: radial-gradient(circle, transparent, #373737 250%);
color: #EEE;
font-family: "Pixel Tactical";
}

.game-screen-header span{
position: absolute;
left: 2%;
bottom: 2%;
font-size: 200%;
color: #30622f;
}

.lcd-screen{
  margin: 0;
background-color: #9bbc0f;
background-image: url("../images/DMPattern.png");
background-repeat: repeat;
  box-shadow: inset 0px 0px 20px #373;
}

.lcd-screen h3 span{
  left: 0rem;
  top: 0.4rem;
  display: inline-block;
  position: relative;
}

#slogan-line {
  visibility: hidden;
  animation: showElement 1.25s;
  animation-fill-mode: forwards;
}

.lcd-screen h3 span span {
  left: initial;
  top: initial;
  position:relative;
  display: inline-block;
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: .75em solid #30622f; /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  animation: 
    typing 1s steps(14, end),
    blink-caret .75s step-end infinite;
}

/* The typing effect */
@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: #30622f; }
}

/* Scroll banner up */
@keyframes scroll-up-banner {
  from { top: 2rem }
  to { top: 0rem }
}

/* Reveal subline */
@keyframes showElement {
  99% {visibility: hidden;}
  100% {visibility: visible;}
}

header .lcd-screen{
  margin: 0 auto;
  width: 92%;
  overflow: hidden;
}

header .lcd-screen h3{
  font-family: 'Pixel Tactical';
  color: #30622f;
  font-size: 1.75em;
  line-height: 1em;
  padding: 0.25em;
  display: table-cell;
  position: relative;
  overflow: hidden;
  top: 2rem;
  animation:
    scroll-up-banner 0.1s linear 0.5s 1 normal forwards;
  animation-delay: 1.25s;
}

.navbar-light {
background-color: #eee;
box-shadow: inset 0px -1px 4px #3c3c3c;
  margin: 12px 0 0;
  position: relative;
  padding: 10px;
  border-radius: 0 0 12px 48px;
}

.row {
  margin: 0;
  position: relative;
}

#home-page .col-lg-4 span {
  height: 20rem;
  text-align: justify;
}
#home-page .col-lg-8 span {
  height: 10rem;
  text-align: justify;
}

.inner-marketing-row-wrapper .col-lg-8, .inner-marketing-row-wrapper .col-lg-4{
  margin: 0;
  padding: 0;
  outline: 2px solid #eee;
}

.inner-marketing-row-wrapper .row{
  margin: 0;
}

.small-box{
  padding: 5px;
}

.small-box .col-sm-6, .small-box .col-sm-12{
  padding: 5px;
}

.small-box img{
  width: 100%;
  border: 1px solid #eee;
  border-radius: 5px;
}

.small-box .btn{
  padding: 0;
}

.modal-lg img{
  width: 100%;
}

.navbar-toggle{
  display: block;
  height: 1.75rem;
  width: 4rem;
  left: 1rem;
  position: relative;
  border: 1px solid #373737;
  border-radius: 10px;
  background-color: #ccc;
  box-shadow: 0px 1px 4px #373737;
  color: #eee;
  text-shadow: 1px 1px 2px #373737;
  margin-bottom: 0.3rem;
}

.navbar-toggler{
  margin: 0 10%;
}

@media (min-width: 768px) {
  .navbar-toggle{
    display: none;
  }
  #slogan{
    text-align: right;
  }
  header .lcd-screen{
    width: 96%;
  }
}

@media (max-width: 1024px) { 
  #wrapper {
    width: 98%;
  }
}